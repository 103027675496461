.header {
    width: calc(100vw - 320px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-mini {
    width: calc(100vw - 60px);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-text {
    letter-spacing: 13px;
    padding: 20px;
    font-size: 3.2rem;
    font-weight: 400;
    text-shadow: #837d7d 0.03em 0.01em 0.02em;
}
