.hide-menu-btn {
    background: #003153;
    color: white;
    border: 0;
    width: 60px;
    height: 55px;
    padding: 0 20px;
    margin: 0 0 0 256px;
}

.small-hide-menu-btn {
    background: white;
    color: #003153;
    margin: 0;
}

.hide-menu-icon {
    transform: scale(1.8);
    margin: 0 0 -3px -1px;
    background: transparent;
}

.hide-menu-btn:hover {
    background: #284F6F;
}

.small-hide-menu-btn:hover {
    background: #dcdcdc;
}

.background-hide-menu-btn {
    background: #003153;
    height: 55px;
    width: 320px;
}