body {
    background: white;
}

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    background-size: cover;
}

.standard {
    width: calc(100vw - 320px);
    margin-left: 320px;
}


.mini {
    width: 100vw;
    padding-left: -30px;
}
