.menu-btn {
    background: #003153;
    border: 0;
    color: white;
    width: 320px;
    line-height: 70px;
    font-size: 1.3rem;
    display: block;
    text-decoration: none;
    text-align: center;
}

.menu-btn:hover {
    background: #284F6F;
}


