.btn {
    font-size: 1.3rem;
    width: 250px;
    line-height: 40px;
    border-radius: 20px;
    background: #003153;
    border: 0;
    color: white;
    display: block;
    margin: 10px auto 0 auto;
    padding: 0 0 0 0;
    text-decoration: none;
    text-align: center;
}

.btn:hover {
    background: #284F6F;
}