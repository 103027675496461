.input {
    border-radius: 20px;
    color: #151515;
    text-align: center;
    font-weight: 400;
    text-shadow: #837d7d 0.03em 0.01em 0.02em;
    width: 350px;
    line-height: 30px;
    margin: 10px;
    background: white;
    border: #a2a2a2 1px solid;
}

.input-box {
    text-align: center;
    font-weight: 400;
    text-shadow: #837d7d 0.03em 0.01em 0.02em;
    font-size: 1.7rem;
    margin: 20px;
}

.text {
    letter-spacing: 4px;
    padding: 20px;
    font-size: 2rem;
    font-weight: 400;
    text-shadow: #837d7d 0.03em 0.01em 0.02em;
    text-align: center;
}

.box {
    text-align: center;
    margin: 60px;
}