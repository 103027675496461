.log-btn {
    background: #003153;
    color: white;
    width: 320px;
    min-height: 100px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}

.log-btn:hover {
    background: #284F6F;
}

.log-box:hover {
    background: #003153;
}

.person-icon {
    transform: scale(1.8);
    color: white;
    border-radius: 15px;
    background: transparent;
}

.log-text {
    font-size: 1.5rem;
    border: 0;
    letter-spacing: 2px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-width: 240px;
    padding-right: 70px;
}

.icon-box {
    min-width: 80px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.logged-box {
    display: flex;
    max-height: 100px;
    min-height: 60px;
    min-width: 240px;
    align-content: center;
    flex-direction: column;
    font-size: 1.1rem;
    justify-content: center;
    padding-right: 70px;
}

